import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import { AlreadyUploadedGettyImages } from '../../../../Partials/Sidebar/media-refactored/constants/general';
import GettyImageModel from '../../models/getty-image.model';
import { uploadGettyImage } from '../../helpers/getty-images.helper';
import { DATA_QA_ATTRIBUTES } from '../../../../Partials/Sidebar/media-refactored/constants/data-qa.attributes';
import GettyModal from './getty-modal';
import GettyApiService from '../../helpers/getty-api.service';

const GettyImageListing: FunctionComponent = () => {
	const [t] = useTranslation();
	const gettyEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.GETTY_IMAGES);
	const gettyInstance = new GettyApiService();
	const [alreadyUploadedGettyImages, setAlreadyUploadedGettyImages] = useState<AlreadyUploadedGettyImages>([]);
	const [isGettyCollapseOpen, setIsGettyCollapseOpen] = useState(false);
	const [selectedItemDownloadSize, setSelectedItemDownloadSize] = useState<string>('');
	const [requestsExecuting, setRequestsExecuting] = useState(false);

	useEffect(() => {
		setSelectedItemDownloadSize('');
	}, [isGettyCollapseOpen]);

	if (!gettyEnabled) {
		return null;
	}

	const onDownLoadSizeSelect = (size: string) => {
		setSelectedItemDownloadSize(size);
	};

	const onGettySelectImage = (image: GettyImageModel) =>
		uploadGettyImage(
			image,
			selectedItemDownloadSize,
			{},
			alreadyUploadedGettyImages,
			setAlreadyUploadedGettyImages,
			setIsGettyCollapseOpen,
			gettyInstance,
			setRequestsExecuting,
		);

	return (
		<Row>
			<Col>
				<Button
					className='m-0 p-0'
					color='link'
					onClick={() => setIsGettyCollapseOpen(!isGettyCollapseOpen)}
					id={DATA_QA_ATTRIBUTES.TOGGLE_GETTY_IMAGES}
				>
					{t('toggle_getty')}
				</Button>
				<GettyModal
					onSelect={onGettySelectImage}
					isOpen={isGettyCollapseOpen}
					toggleModal={setIsGettyCollapseOpen}
					gettyInstance={gettyInstance}
					onDownLoadSizeSelect={onDownLoadSizeSelect}
					selectedItemDownloadSize={selectedItemDownloadSize}
					requestsExecuting={requestsExecuting}
				/>
			</Col>
		</Row>
	);
};

export default GettyImageListing;
